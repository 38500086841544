@import './variables';

/* This file has custom notation forced by font generator package so we disable the linter*/
/* stylelint-disable */

/***
  * Custom Fonts
  */

/***
  * _ga svg import is handled by IconfontWebpackPlugin plugin
  */

._ga {
    display: flex;
    align-items: center;
    justify-content: center;
}

._ga {
    &--user-solid-icon:after {
        font-icon: url('../icons/user-solid-icon.svg');
    }

    &--split-icon:after {
        font-icon: url('../icons/split-icon.svg');
    }

    &--gamefound-backer:after {
        font-icon: url('../icons/gamefound-backer.svg');
    }

    &--gamefound-flat:after {
        font-icon: url('../icons/gamefound-flat-icon.svg');
    }

    &--gamefound-outline:after {
        font-icon: url('../icons/gamefound-outline-icon.svg');
    }

    &--plus-bold-icon:after {
        font-icon: url('../icons/plus-bold-icon.svg');
    }

    &--heart-solid-icon:after {
        font-icon: url('../icons/heart-solid-icon.svg');
    }

    &--play-solid:after {
        font-icon: url('../icons/play-solid.svg');
    }

    &--stretch-pay:after {
        font-icon: url('../icons/stretch-pay.svg');
    }

    &--revert:after {
        font-icon: url('../icons/revert-icon.svg');
    }

    &--cookie-icon:after {
        font-icon: url('../icons/cookie-icon.svg');
        font-size: $font-size-big;
    }

    &--help-icon:after {
        font-icon: url('../icons/help-icon.svg');
    }

    &--stretch-pay-icon:after {
        font-icon: url('../icons/stretch-pay-icon.svg');
    }

    &--books-icon:after {
        font-icon: url('../icons/books-icon.svg');
    }

    &--sparkles:after {
        font-icon: url('../icons/sparkles.svg');
    }    
    
    &--trophy:after {
        font-icon: url('../icons/trophy.svg');
    }    
    
    &--magnifying-glass:after {
        font-icon: url('../icons/magnifying-glass.svg');
    }
    
    &--user-icon:after {
        font-icon: url('../icons/user-icon.svg');
    }
    
    &--grid-horizontal:after {
        font-icon: url('../icons/grid-horizontal.svg');
    }
    
    &--fire:after {
        font-icon: url('../icons/fire.svg');
    }

    &--wreath-laurel-icon:after {
        font-icon: url('../icons/wreath-laurel-icon.svg');
    }
        
    &--lighthouse:after {
        font-icon: url('../icons/lighthouse.svg');
    }

    &--rocket:after {
        font-icon: url('../icons/rocket.svg');
    }

    &--shopping-bag:after {
        font-icon: url('../icons/shopping-bag.svg');
    }

    &--clock:after {
        font-icon: url('../icons/clock.svg');
    }

    &--adfound-outline:after {
        font-icon: url('../icons/adfound-outline-icon.svg');
    }
}
